#ourteam {
    background-color:white;
    width:100%;
    min-height:926px;
    @media (max-width: @screen-sm-max){
      padding-top: 50px !important;
      padding-bottom: 0px !important;
    }
}

#ourteam .row_wrapper {
    margin:0 auto;
    float:none;
}
#ourteam>.row {
    width:980px;
    margin:0 auto;
}
#ourteam>.row>div {
    padding:2%;
}
#ourteam .circle_block {
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    border-radius: 999px;
    position:relative;
    // Create a 1:1 rectangle (square)
    padding-bottom: 100%;
}
#ourteam .circle_block img {
    position: absolute;
    top: 0px;
    left: 0px;
    width:100%;
    height: auto;
}
#ourteam .circle_block .text {
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;
  position:absolute;
  left:0px;
  right:0px;
  top:0px;
  bottom:0px;
  margin:auto;
  width:0;
  height:0;
  background-color:rgba(255,0,0,.7);

  > .text-container {
    width: 100%;
    display: none;
    transform-style: preserve-3d;
    position: absolute;
    top: 50%;
    transform         : translateY(-50%);
    -webkit-transform : translateY(-50%);
    -moz-transform    : translateY(-50%);
    -ms-transform     : translateY(-50%);
    -o-transform      : translateY(-50%);
    line-height:1.2em;

    .name, .position {
      color: #fff;
    }

    .name {
      font-family:Bitter_Regular;
      font-size:24px;
      // Handle really small screen
      @media (max-width: 360px){
        font-size: 20px;
      }
    }

    .position {
      font-family:Avenir_Next;
      font-size:18px;
      line-height:1em;
      @media (max-width: 360px){
        font-size: 14px;
      }
    }
  }
}
//#ourteam .circle_block .text * {
//    color:#fff;
//    width:100%;
//    text-align:center;
//    position:absolute;
//    display:none;
//    line-height:1.2em;
//}
//#ourteam .circle_block .text .position {
//    line-height:1em;
//}
//#ourteam .circle_block .text .name {
//    font-family:Bitter_Regular;
//    font-size:24px;
//    top:35%;
//}
//#ourteam .circle_block .text .position {
//    font-family:Avenir_Next;
//    font-size:18px;
//    top:55%;
//}
/* Small devices (tablets, 980px and down) */
@media (max-width: 980px) {

    #ourteam>.row {
        width:100%;
    }
}
