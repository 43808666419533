#products * {
}

#products {
  position:relative;
  
	background-color: white;        // new css @ 20150819
	padding-top: 60px !important;   // new css @ 20150819
	padding-bottom: 60px !important;   // new css @ 20150819

  @media (min-width: @screen-md-min){
    padding-top: 20 + 72px !important;
  }

  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max){
    padding-top: 8 + 64px !important;
  }

  .content > div {
    position:relative;
    left:0px;
    top:0px;

    &.clients_container {
      padding:35px 0 100px 0;
      background:url("/images/bg_pattern00.jpg") repeat scroll center center rgba(0, 0, 0, 0);
    }
  }
  .product_block {
    position:relative;
    padding:0;
    margin:0px;
  }
  .card {
    border: medium none;
    float: left;
    position: relative;
    transform-style: preserve-3d;
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    opacity: 0;
    width: 100%;
    &.flipped {
      transform:rotateY(180deg);
      -webkit-transform:rotateY(180deg);
      -moz-transform:rotateY(180deg);
      -ms-transform:rotateY(180deg);
      -o-transform:rotateY(180deg);
    	opacity: 1;
    }

    * {
      color: white;
	    font-family: Helvetica, Arial, sans-serif;    // new css @ 20150819
    }

    figure {
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      color: #FFFFFF;
      display: block;
      font-weight: bold;
      height: 100%;
      position: absolute;
      text-align: center;
      width: 100%;

      &.back {
        position: relative;
      }

      img.backgrd {
        border: medium none;
        display: block;
        width:100%;
      }
    } // figure


    .front {
      background: none repeat scroll 0 0 transparent;
      color: #ECF0F1;
      text-align: center;
      transition: all 1200ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    } // .front


  } // .card
}

#products .card .back {
  background: none repeat scroll 0 0 transparent;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  .info {
    -moz-box-sizing: border-box;
    height: 100%;
    opacity: 0;
    /* padding-top: 15%; */
    position: absolute;
    top: 0;
    transition: all 1200ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    width: 100%;

    h3, h4 {
        font-weight: 300;
        letter-spacing: -0.04em;
        position: relative;
        transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
        width: 100%;
    }

    h3[itemprop=name]{
      margin-bottom: 0;
    }
    h4[itemprop=description]{
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 3%;
      font-size: 12px;
      overflow: hidden;
    }

    h3 {
      font-size: 22px;
      height: 24px;
      line-height: 28px;
      margin:3% auto;
    }

    h4 {
      font-size: 16px;
      height: 30px;
      line-height: 30px;
      opacity: 0;
      font-family:Avenir_Next_Italic;
    }
    span.line {
        background: none repeat scroll 0 0 #FFFFFF;
        display: block;
        height: 1px;
        margin: 0 auto;
        opacity: 1;
        overflow: visible;
        position: relative;
        transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
        width: 12%;
    } // span.line

    img {
      transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
      margin:4% auto;
      display:block;
    }
  } // .info
}
#products .card .back.hover {
  .info {
    background: none repeat scroll 0 0 rgba(236, 37, 42, 0.9);
    opacity: 1;

    span.line {
      opacity: 1;
      width: 84%;
    }

    h4 {
      opacity: 1;
    }
  } // .info
}

#products .card .back:hover {
  @media (min-width: @screen-md-min){
    .info {
      background: none repeat scroll 0 0 rgba(236, 37, 42, 0.9);
      opacity: 1;

      span.line {
        opacity: 1;
        width: 84%;
      }

      h4 {
        opacity: 1;
      }
    } // .info
  }
}

#products .clients_container>.row {
    width:980px;
    margin:0 auto;
}
#products .client_block {
}
#products .client_block .card .back .info h3 {
    font-size: 24px;
    color:#ec252a;
}
#products .client_block .card .back .info h4, #products .client_block .card .back .info h4 * {
    font-size: 18px;
    color:#3d3131;
    font-family:Avenir_Next_Italic;
}
#products .client_block .card .back:hover img {
    opacity: .05;
}

#products .client_block .card .back.hover .info {
  background: none repeat scroll 0 0 rgba(200, 200, 200, 0.8);
  opacity: 1;
}
#products .client_block .card .back:hover .info {
  @media (min-width: @screen-md-min){
    background: none repeat scroll 0 0 rgba(200, 200, 200, 0.8);
    opacity: 1;
  }
}

/* Small devices (mobile, 768px and down) */
@media (max-width: @screen-xs-max) {
  #products .card .back .info {
      /* padding-top: 10%; */
  }
  #products .clients_container>.row {
      width:100%;
  }
  #products .tab_btns {
      width:100%;
  }
  #products .tab_btns>div {
      width:50%;
      margin:0;
      float:left;
  }
  #products .client_block {
      padding:0;
  }
  #products .client_block .card .back .info h3 {
      font-size: 300%;
  }
  #products .client_block .card .back .info h4,
  #products .client_block .card .back .info h4 * {
      font-size: 180%;
  }

  /* new css @ 20150819 */
  #products {
    padding-top: 30px !important;
    padding-bottom: 0px !important;

    &.fixed {
      padding-top: 74px !important;
    }

    .media.products-container {
      display: block;
      width: 100%;

      .media-left,
      .media-body {
        display: block;
        width: 100%;
        min-width: initial !important;
      }
    }
  }

  #products .products .product-container {
    position: relative !important;
    width: 100% !important;
    left: 0px !important;
    z-index: 9999;

  }
}
/* Small devices (tablets, 980px and down) */
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  #products .clients_container>.row {
      width:100%;
  }

  // new css @ 20150819
  #products .media.products-container .media-left {
  	min-width: 200px;
  	padding: 0px;
  }
  #products .media.products-container .media-body .product-container{
    &[data-slot-order="4"],
    &[data-slot-order="5"],
    &[data-slot-order="6"],
    &[data-slot-order="7"] {
      .card .back .info {
        h3 {
          font-size: 18px;
          height: 18+2px;
        }

        h4 {
          font-size: 12px;
          height: 12px * 2;
          line-height: 12px * 2;
        }

        h4.desc a {
          font-size: 12px;
        }
      }
    }
  }
}

/* new css @ 20150819 */

/* tablet specific */
#products .media.products-container {
  .media-left {
  	padding: 0px;
    @media (min-width: @screen-md-min){
  	  min-width: 300px;
    }

    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max){
      min-width: 200px;
    }
  }
}

#products .products {
	margin-top: -8px;
  .row {
  	margin: 0px -8px;
  }

  .col-sm-8,
  .col-sm-6,
  .col-sm-4,
  .col-xs-12 {
  	padding-left: 8px;
  	padding-right: 8px;
  }

  .people_container {
    .row {
      position: relative;
    }
  }
}

#products .products .product-container {
	//padding-top: 8px;
	//padding-bottom: 8px;
  padding: 8px;
  .product_block {
    width: 100%;
    height: 100%;
  	display: block;
    .card {
    	display: block;
    	width: 100%;
    	height: 100%;

      .back .background-image {
      	display: block;
      	width: 100%;
      	height: 100%;
      	background-size: cover;
      	background-position: center;
      }
    }
  } // .product_block

  position: absolute;
  // The move animation
  transition: top 0.3s linear, left 0.3s linear;
}

#products .categories-menu {
  a:hover,
  a:focus
  {
  	text-decoration: none;
  }

  .category {
  	margin-bottom: 20px;
    > .name {
    	color: #808080;
    	margin-bottom: 5px;
    	text-transform: uppercase;
    	font-weight: bold;
    }

    &.active > .name {
	    color: #ec252a;
    }

    .subcategories-menu {
      .subcategory {
        > .name {
	        color: #999999;
	        margin-bottom: 5px;
        }

        &.active > .name {
	        color: #ec252a;
        }
      }
    }
  } // .category
}

/* mobile version */
@productMenuTop: 44px;
#products .categories-menu .visible-xs-block {
	font-size: 18px;

  &.fixed {
    position: fixed;
    top: 64px;
    left: 0px;
    overflow: scroll;
    //height: 100%;
    //height: ~"calc(100% - @{productMenuTop})";
    height: 40px;
    z-index: 10000;
    width: 100%;
  }

  > button[data-toggle] {
  	width: 100%;
  	background-color: white;

    &:focus {
	    border: 0;
	    outline: none;
    }

    span {
	    color: #b3b3b3;
	    vertical-align: middle;
	    font-size: 32px;
	    text-transform: uppercase;
    }

    span.arrow {
    	background-image: url('/images/arrow_expand@2x.png');
    	width: 20px;
    	height: 12px;
    	background-size: 100%;
    	background-repeat: no-repeat;
    	display: inline-block;
    }
  }

  > button.collapsed[data-toggle] span.arrow {
	  background-image: url('/images/arrow_minimise@2x.png');
  }

  > #mobile-category-menu {
	  text-align: center;
    background-color: white;

    .category > .name {
    	font-size: 16px;
    }

    .subcategories-menu .subcategory > .name{
    	font-size: 14px;
    }
  }
} // #products .categories-menu .visible-xs-block


.vertical-align {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}


