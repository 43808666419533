@charset "utf-8";
/* CSS Document */

/* For IE 10 mentioned in Bootstrap */
@-webkit-viewport   { width: device-width; }
@-moz-viewport      { width: device-width; }
@-ms-viewport       { width: device-width; }
@-o-viewport        { width: device-width; }
@viewport           { width: device-width; }
/* For IE 10 mentioned in Bootstrap */

@font-face {
    font-family: Avenir_Next;
    src: url('/fonts/AvenirNextLTPro-Regular.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir_Next_Bold';
    src: url('/fonts/AvenirNextLTPro-Medium.otf');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir_Next_Italic';
    src: url('/fonts/AvenirNextLTPro-It.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bitter_Bold';
    src: url('/fonts/Bitter-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Bitter_Regular';
    src: url('/fonts/Bitter-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

html, #content_wrapper {
    width:100%;
    height:100%;
}

body {
    line-height:1.4em;
    width:100%;
    height:100%;
    position:relative;
    background-color:#fff;
    overflow-x:hidden;
}

html, body, html body * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    margin:0px;
    border:0px;
    padding:0px;
    font-size: 100%;
    line-height: 1.4em;
    font-family:Bitter_Regular;
    color:black;
}
html body .row {
    padding:0px;
    margin:0px;
}

a img {
    border:none;/* for IE only */
}

.btn_an {
    display:inline-block;
    width:56px;
    height:40px;
    background: transparent url("/images/btn_an_w.png") no-repeat 0 0;
    pointer:cursor;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/btn_an_w@2x.png');
      background-size: 100% 200%;
    }
}
.btn_an:hover {
    background-position:0 -40px;
}
.btn_an.disabled {
    pointer:default;
}
.btn_an.disabled:hover {
    background-position:0 0;
}
.btn_an.color {
    background-image:url("/images/btn_an_c.png");
}
.btn_ios {
    display:inline-block;
    width:56px;
    height:40px;
    background: transparent url("/images/btn_ios_w.png") no-repeat 0 0;
    pointer:cursor;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/btn_ios_w@2x.png');
      background-size: 100% 200%;
    }
}
.btn_ios:hover {
    background-position:0 -40px;
}
.btn_ios.disabled {
    pointer:default;
}
.btn_ios.disabled:hover {
    background-position:0 0;
}
.btn_ios.color {
    background-image:url("/images/btn_ios_c.png");
}
.btn_web {
    display:inline-block;
    width:56px;
    height:40px;
    background: transparent url("/images/btn_web_w.png") no-repeat 0 0;
    pointer:cursor;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/btn_web_w@2x.png');
      background-size: 100% 200%;
    }
}
.btn_web:hover {
    background-position:0 -40px;
}
.btn_web.disabled {
    pointer:default;
}
.btn_web.disabled:hover {
    background-position:0 0;
}
.btn_web.color {
    background-image:url("/images/btn_web_c.png");
}
.btn_engine {
    display:inline-block;
    width:170px;
    height:40px;
    background: transparent url("/images/btn_engine.png") no-repeat 0 center;
    pointer:cursor;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/btn_engine@2x.png');
      background-size: 100% 100%;
    }
}
.frame_btn {
    text-transform:uppercase;
    font-size:15px;
    border:1px solid #3d3131;
    width:320px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin:20px auto;
    cursor:pointer;
    color:#3d3131;
    font-family:Avenir_Next;
}
.frame_btn:hover, .frame_btn:active {
    color:white;
    border:1px solid #ec252a;
    background-color:#ec252a;
}

.tab_btns {
    text-align:center;
    margin:50px auto 35px auto;
}
.tab_btns>div {
    display:inline-block;
    font-family:Avenir_Next;
    color:#7a7a7a;
    font-size:18px;
    text-transform:uppercase;
    cursor:pointer;
    margin:0 10px;
    border-bottom:5px solid rgba(61, 49, 49, .3);
    width:160px;
    text-align:center;
}
.tab_btns>div:hover, .tab_btns>div.active {
    color:#3d3131;
    cursor:default;
    border-bottom:5px solid #ec252a;
}
.tab_btns>div:hover {
    cursor:pointer;
}

#news_overlay, #content_overlay, #master_holder {
    position:fixed;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    width:100%;
    height:100%;
    display:none;
    z-index:9900;
}
#content_overlay {
    background-color:#fff;
    overflow-y:scroll;
}
#content_overlay .main {
    width:980px;
    position:relative;
    margin:0 auto;
}
#content_overlay .btn_close {
    background: transparent url("/images/btn_close.png") no-repeat 0 0;
    width:42px;
    height:42px;
    position:absolute;
    right:-10px;
    top:66px;
    display:block;
    cursor:pointer;
}
#content_overlay .btn_close:hover {
    background-position: 0 -42px;
}
#news_overlay {
    /*background: #fff url("/images/bg_pattern01.jpg") repeat 0 0;*/
    background-color:rgba(0,0,0,.75);
    overflow:scroll;
}
#news_overlay .main {
    max-width:980px;
    min-height:100%;
    position:relative;
    margin:40px auto 0 auto;
    /*padding-top:60px;*/
    background-color:#fff;
    padding:70px 30px;
}
#news_overlay .btn_close {
    background: transparent url("/images/btn_overlay_close.png") no-repeat 0 0;
    width:80px;
    height:40px;
    position:absolute;
    right:0;
    top:0;
    display:block;
    cursor:pointer;
}
#news_overlay .btn_close:hover {
    background-position: 0 -40px;
}
#news_overlay .tab_btns {
    margin:0px auto 50px 0px;
}
#news_overlay .news_details>* {
    padding:10px 120px;
    width:100%;
}
#news_overlay .news_details>.category {
    color:#ec252a;
    font-family:Avenir_Next;
    font-size:18px;
}
#news_overlay .news_details>.title {
    font-size:24px;
}
#news_overlay .news_details>.date {
    font-size:18px;
    color:#787878;
    font-family:Avenir_Next;
}
#news_overlay .news_details>.line {
    padding:0px;
    width:100%;
    height:1px;
    background-color:#ED3537;
    margin:10px 0;
}
#news_overlay .news_details>.desc {
    font-size:16px;
}
#news_overlay .news_details>.desc img {
    max-width:100%;
}

/* Small devices (tablets, 980px and down) */
@media (max-width: 980px) {

    #news_overlay .main {
        width:100%;
    }
    #news_overlay .tab_btns {
        width:100%;
    }
    #news_overlay .tab_btns>.item {
        width:18%;
        margin-left:0px;
        margin-right:0px;
    }
    #news_overlay .news_details>* {
        padding:10px 5%;
    }
}
/* contact section end */

/* award section begin */
#content #award.section{
    padding:50px 15px;
    background-color:white;
    div.row{
        >a{
            displiay:inline-block;
            text-align:center;
            padding-top:10px;
            padding-bottom:20px;
            >img{
                max-width:100%;
            }
        }
    }
}
/* award section end */
#master_holder {
    z-index:9999;
}

#header {
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    background-color:rgba(255, 255, 255, 0.95);
    z-index:10000;
    border-bottom:1px solid rgba(0, 0, 0, 0.05);
}

#footer {
    position:relative;
    width: 100%;
    border-top: 1px solid #c3c3c3;
    background-color: white;
    z-index: 1000;
    padding: 5px 15px;
}
#footer>div {
    width:100%;
    //height:70px;
    line-height:50px;
    margin:0 auto;
    color:#3d3131;
    text-align:center;
}
#footer>div * {
    color:#3d3131;
    font-family:Avenir_Next;
}
#footer>div>.row{
    margin-left: -15px;
    margin-right: -15px;
}
#footer>div .left {
    float:left;
}

@media (max-width: 768px) {
    #footer {
        display:none;
    }
}
#web_menu {
    width:980px;
    height:120px;
    margin:0 auto;
    padding:0px 0px;
    position:relative;
}
#web_menu.small {
    width:980px;
    height:70px;
}
#web_menu .item {
    font-family:Avenir_Next;
    display:block;
    float:left;
    width:120px;
    text-align:center;
    margin:76px 20px 20px 0px;
    color:#3d3131;
    font-size:16px;
    z-index:1;
    position:relative;
    text-transform:uppercase;
}
#web_menu .item.active {
    /*color:#fff;*/
    cursor:default;
}
#web_menu .item:hover {
    color:#ff0000;
    text-decoration:none;
}
#web_menu .item.active:hover {
}
#web_menu.small .item {
    margin:35px 20px 10px 0px;
}
#web_menu #logo.item {
    background: transparent url("/images/logo_text.png") no-repeat center center;
    width:100px;
    height:88px;
    margin:10px 20px 0px 0px;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/logo_text@2x.png');
      background-size: 100% 100%;
    }
}
#web_menu.small #logo.item {
    background-image: url("/images/logo_notext.png");
    height:50px;
    margin:10px 20px 10px 0px;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/logo_notext@2x.png');
      background-size: 100% 100%;
    }
}
#web_menu .item.langs {
    float:right;
    margin:72px 0px 10px 0px;
}
#web_menu.small .item.langs {
    margin:30px 0px 5px 0px;
}
#web_menu .langs .lang {
    display:block;
    float:left;
    width:40px;
    height:24px;
    margin:0px;
    cursor:pointer;
}
#web_menu .langs .lang[data-val=sc] {
    background: transparent url("/images/lang_chs.png") no-repeat 0 0px;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/lang_chs@2x.png');
      background-size: 100% 300%;
    }
}
#web_menu .langs .lang[data-val=tc] {
    background: transparent url("/images/lang_cht.png") no-repeat 0 0px;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/lang_cht@2x.png');
      background-size: 100% 300%;
    }
}
#web_menu .langs .lang[data-val=en] {
    background: transparent url("/images/lang_eng.png") no-repeat 0 0px;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/lang_eng@2x.png');
      background-size: 100% 300%;
    }
}
#web_menu .langs .lang:hover {
    background-position: 0px -24px;
}
#web_menu .langs .lang.active, #web_menu .langs .lang:active {
    background-position: 0px -48px;
}
#web_menu .langs .lang.active {
    cursor:default;
}
#web_menu .overlay {
    position:absolute;
    width:120px;
    height:0px;
    z-index:0px;
    background-color:#ec252a;
}
#mobile_menu {
    display:none;
    position:relative;
}
#mobile_menu .menu_btn {
    display:block;
    position:absolute;
    margin:auto;
    top:0;
    bottom:0;
    left:10px;
    background: white url("/images/m_btn_menu.png") no-repeat 0 0;
    background-size:cover;
    width:32px;
    height:32px;
}
#mobile_menu .menu_btn.on {
    background-position:0 -32px;
}
#mobile_menu a.item {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display:block;
    width:156px;
    height:32px;
    margin:auto;
    background: white url("/images/m_logo.png") no-repeat center center;
    background-size:cover;
}
#mobile_menu_content {
    position:fixed;
    left:0;
    top:65px;
    width:100%;
    background-color:rgba(236,37,42,.97);
    //z-index:1001;
    z-index:10001;
    padding:10px 0px 20px 0px;
    //
    overflow: scroll;
    height: 240px;
}
#mobile_menu_content .item {
    width:100%;
    height:60px;
    line-height:60px;
    color:#781113;
    font-size:28px;
    text-transform:uppercase;
    font-family:Avenir_Next;
    text-align:center;
    cursor:pointer;
    display:block;
}
#mobile_menu_content .menuitem.active {
    color:#fff;
}
#mobile_menu_content .item .langs {
    height:50px;
}
#mobile_menu_content .langitem {
    width:80px;
    height:50px;
    line-height:50px;
    border:2px solid transparent;
    display:inline-block;
    color:#A61A19;
    text-align:center;
    cursor:pointer;
    font-size:25px;
}
#mobile_menu_content .langitem:nth-child(2) {
    margin-left:9%;
    margin-right:9%;
}
#mobile_menu_content .langitem.active {
    border:2px solid #fff;
    color:#fff;
}
@media (max-width: 1000px) {
    #web_menu {
        display:none;
    }
    #mobile_menu {
        display:block;
        width:100%;
        height:64px;
        background-color:white;
    }
    body #content {
        margin-top:87px;
    }
}

#btn_contact_us {
    position:fixed;
    right:20px;
    bottom:20px;
    z-index:1000;
}

#icon_moving_down {
    position:fixed;
    right:0px;
    left:0px;
    margin:auto;
    bottom:20px;
    background: transparent url("/images/home_down_ani.png") no-repeat 0 0px;
    width:32px;
    height:61px;
    z-index:1000;
}
#icon_moving_down[data-val=step1] {
    background-position:-32px 0px;
}
#icon_moving_down[data-val=step2] {
    background-position:-64px 0px;
}
#icon_moving_down[data-val=step3] {
    background-position:-96px 0px;
}
#icon_moving_down[data-val=step4] {
    background-position:-128px 0px;
}
#icon_moving_down[data-val=step5] {
    background-position:-160px 0px;
}

#topbg {
    position:fixed;
    width:100%;
    height:1000px;
    top:0;
    left:0;
    right:0;
}
#topbg>div {
    width:100%;
    height:100%;
    position:absolute;
}
#topbg .bg {
    background: transparent url("/images/home_bg.jpg") no-repeat center center;
    background-size:cover;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/home_bg@2x.jpg');
    }
}
#topbg .blur {
    background: transparent url("/images/home_bg_blur.jpg") no-repeat center center;
    background-size:cover;
    opacity:0;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/home_bg_blur@2x.jpg');
    }
}

#content {
    margin-top:146px;
    position:relative;
}
#content .section {
    width:100%;
    clear:both;
    position:relative;
    padding-top:200px;
}
#content .section .section_header {
    text-align:center;
    font-size:48px;
    text-transform:uppercase;
    color:#B8B8B8;
    padding:10px 0px;
    font-family:Avenir_Next;
}
#content #services.section .section_header {
    color:#9B191B;
}
/* home section start */
#index {
    width:100%;
    height:926px;

    @media (max-width: @screen-sm-max) {
      padding-top: 50px !important;
      height: 480px !important;
    }
}
#index .text {
    max-width:870px;
    text-align:center;
    margin:0 auto;
    padding-top:0px;
}
#index .text * {
    /*text-shadow:1px 2px 4px rgba(24,20,23,.75);*/
    color:white;
}
#index .text .row1 {
    font-family:Bitter_Bold;
    font-size:100px;
}
#index .text .row2 {
    font-family:Bitter_Bold;
    font-size:46px;
}
#index .text .line {
    width:100%;
    height:1px;
    background-color:white;
    margin: 12px 0px;
}
#index .text .desc {
    font-family:Avenir_Next;
    font-size:32px;
}
#index .text .icons {
    width:100%;
    text-align:center;
}
#index .text .icons>div {
    margin:20px 2%;
}
@media (max-width: 1000px) {
    #index .text .row1 {
        font-size:8vw;
    }
    #index .text .row2 {
        font-size:5vw;
    }
    #index .text .desc {
        font-size:4vw;
        line-height:2em;
        padding:0 2em;
    }
}
/* home section end */

/* products section start */
@import "products.less";
/* products section end */

/* services section start */
#services.section {
    padding-top:0;
}
#services .content_up {
    background: transparent url("/images/bg_pattern02.jpg") repeat 0 0;
    z-index:1;
    position:relative;
    padding:50px 0px;
}
#services .content_up .row {
    width: 100%;
    margin: 0px auto;
    padding: 0 15px;
    max-width: 1020px;
}
#services .content_up .service_block {
    max-width: 478px;
    float: left;
    margin-bottom: 40px;
    width: 48%;
}
#services .content_up .service_block img {
    width:100%;
}
#services .content_up .service_block.right {
    //margin-left:64px;
    margin-left: 34px;
}
#services .content_up .service_block * {
    color:white;
}
#services .content_up .service_block .title {
    font-size:24px;
}
#services .content_up .service_block .line {
    width:100%;
    height:1px;
    background-color:white;
    margin:10px 0px;
}
#services .content_up .service_block .desc {
    font-family:Avenir_Next;
    font-size:18px;
    height:100px;
}
#services .content_down {
    padding:20px 0px 0px 0px;
    position:relative;
    left:0px;
    right:0px;
    margin:0 auto;
    bottom:0px;
    z-index:0;
}
#services .content_down .row {
    text-align:center;
}
#services .content_down .row .left {
    display:inline-block;
    background-color:rgba(255,255,255,.5);
    padding:10px 50px 10px 30px;
    margin-bottom:10px;
    margin-top:20px;
    background: transparent url("/images/google_engine_bubble_web.png") repeat 0 0;
    font-family:Avenir_Next;
    text-align:left;
    width:458px;
    height:300px;
}
#services .content_down .row .left * {
    width:100%;
    color:#fff;
}
#services .content_down .row .left .title {
    font-family:Bitter_Bold;
    /*color:#ec252a;*/
    font-size:24px;
    margin-bottom:20px;
}
#services .content_down .row .left .text {
    font-size:18px;
    /*color:#3d3131;*/
    margin:30px 0px;
}
#services .content_down .row .left .knowmore {
    font-size:15px;
    width:320px;
    height:50px;
    line-height:50px;
    margin:20px auto;
}
#services .content_down .row .left .knowmore span {
    color:#ec252a;
}
#services .content_down .row .left .knowmore:hover span, #services .content_down .row .left .knowmore:active span {
    color:white;
}
#services .content_down .row .right {
    vertical-align:top;
    margin-left:20px;
}
/* Small devices (tablets, 768px and down) */
@media (max-width: 768px) {
    #services .content_up .row {
        width:100%;
        margin:0px auto;
        padding: 0px;
        text-align:center;
    }
    #services .content_up .service_block {
        width:100%;
    }
    #services .content_up .service_block.right {
        margin-left:0;
    }
    #services .content_down .row .right {
        margin-left:0px;
        width:90%;
    }
    #services .content_down .row .left {
        background: transparent none repeat 0 0;
        width:90%;
        height:auto;
        border:1px solid #fff;
        padding:10px 20px 10px 20px;
        margin-left:5px;
        margin-right:5px;
    }
}

/* services section end */

/* news section start */
#news {
    min-height:100px;
    background: transparent url("/images/bg_pattern00.jpg") repeat center center;
    padding:50px 0 50px 0;
}
#news .news_cats {
    max-width:980px;
    margin:0 auto 30px auto;
    width:100%;
}
#news .news_cats>div {
    width:16%;
    max-width:120px;
    margin:0.5%;
}
#news .mobile_section_header {
    display:none;
    cursor:pointer;
}
#news .mobile_section_header:after {
    content: "";
    display:inline-block;
    width:20px;
    height:16px;
    margin:10px 0;
    background:transparent url('/images/m_ico_updown.png') no-repeat;
}
#news .mobile_section_header.active:after {
    background-position:0 -16px;
}

#news .content .row {
    max-width:980px;
    width:100%;
    margin:0 auto;
}
#news .content .row.first {
    text-align:center;
}
#news .content .row.first .news_block {
}
#news .content .row.first .img {
    width:50%;
    height:350px;
    display:block;
    float:left;
    background-repeat:none;
    background-position:center center;
    background-size:cover;
    background-color:#fff;
}
#news .content .row.first .text {
    display:block;
    width:50%;
    height:350px;
    vertical-align:top;
    float:left;
}
#news .content .row.first .title {
    margin:10px 0;
}
#news .morenews {
    margin:50px auto;
    width:250px;
    height:50px;
    line-height:50px;
}
.news_block {
    padding:20px 10px;
    cursor:pointer;
}
.news_block>.img {
    width:100%;
    min-height:10px;
}
.news_block.list>.img {
    width:50%;
    float:left;
    display:block;
}
.news_block>.text {
    background-color:white;
    padding:60px 30px 20px 30px;
}
.news_block.list>.text {
    width:50%;
    float:left;
}
.news_block>.text * {
    text-align:left;
}
.news_block>.text .category {
    color:#ec252a;
    font-family:Avenir_Next;
    font-size:16px;
}
.news_block>.text .title {
    color:#3d3131;
    font-family:Bitter_Regular;
    font-size:22px;
}
.news_block>.text .desc {
    color:#3d3131;
    font-family:Avenir_Next;
    font-size:16px;
}
.news_block>.text .date {
    color:#7a7a7a;
    font-family:Avenir_Next;
    font-size:14px;
    margin-top:10px;
}
#news .row.second .news_block {
    background-color:transparent;
}
#news .row.second .news_block:nth-child(2) {
}
#news .row.second .news_block .img {
    width:100%;
    height:200px;
    background-position:center center;
    background-size:cover;
    background-color:#fff;
}
#news .row.second .news_block .text {
    height:200px;
    overflow:hidden;
    padding:40px 30px 20px 30px;
}
// Mobile and tablet
@media (max-width: @screen-sm-max) {
  #news.section {
    padding-top: 20px;
    padding-bottom: 0px;
  }

    #news .section_header {
        display:none;
    }
    #news .mobile_section_header {
        display:block;
    }
    #news .news_cats.tab_btns {
        display:none;
        border-top:2px solid #ED2525;
        width:100%;
        margin: 0px;
        //margin:0 10px;
    }
    #news .news_cats.tab_btns>div {
        border-bottom:none;
        display:block;
        margin:20px auto;
        width:200px;
        max-width:200px;
        font-size:25px;
        color:#000;
    }
    #news .news_cats.tab_btns>div.active {
        color:#EC2427;
    }
}
@media (max-width: 800px) {
    #news .content .row.first .img {
        width:100%;
    }
    #news .content .row.first .text {
        width:100%;
    }
    .news_block>.text {
        padding-top:40px;
    }
}
/* news section end */

/* ourteam section start */
@import "ourteam.less";
/* ourteam section end */

/* contact section start */
#contact {
    background-color:#fff;
    @media (max-width: @screen-sm-max) {
      padding-top: 50px !important;
    }
}
#contact #GMap_map {
    height:818px;
    width:50%;
    border-right:1px solid #c3c3c3;
    float:left;
}
#contact .text {
    height:818px;
    width:50%;
    padding:50px;
    float:left;
}
#contact .text .title {
    font-family:Avenir_Next;
    font-size:28px;
    color:#ec252a;
    margin-bottom:40px;
}
#contact .text .info {
    cursor:pointer;
    margin:20px 0px;
    display:block;
}
#contact .text .leave_msg {
    display:none;
}
#contact .text .info>* {
    font-family:Avenir_Next;
    font-size:18px;
    color:#3d3131;
    float:left;
}
#contact .text .info .icon {
    width: 33px;
    height: 33px;
    margin-right:35px;
}
#contact .text .info:hover .icon {
    background-position: 0 100%;
}
#contact .text .info .desc {
    width:300px;
}
#contact .text .info { 
  &.email, &.fb {
    .desc { 
      margin: 4px 0px;
    }
  }
}
#contact .text .addr:hover .desc {
    color:#ec252a;
}
#contact .text .email:hover .desc {
    color:#b01f24;
}
#contact .text .fb:hover .desc {
    color:#3a5491;
}
#contact .text .addr .icon {
    background: transparent url("/images/contact_icon_map.png") no-repeat 0 0;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/contact_icon_map@2x.png');
      background-size: 100% 200%;
    }
}
#contact .text .email .icon {
    background: transparent url("/images/contact_icon_mail.png") no-repeat 0 0;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/contact_icon_mail@2x.png');
      background-size: 100% 200%;
    }
}
#contact .text .fb .icon {
    background: transparent url("/images/contact_icon_fb.png") no-repeat 0 0;
    @media (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      /* Retina-specific stuff here */
      background-image: url('/images/contact_icon_fb@2x.png');
      background-size: 100% 200%;
    }
}
#contactform {
    display:block;
    max-width:440px;
    width:100%;
    margin-top: 40px;
}
#contactform>* {
    width:100%;
    border:1px solid #3d3131;
    min-height:50px;
    margin:20px 0px;
    padding:5px 10px;
    display:block;
}
#contactform * {
    color:#3d3131;
    font-family: Avenir_Next;
    font-size:18px;
}
#contactform .row.button {
    border:none;
    padding-right:0px;
}
#contactform label {
    width:30%;
    height:100%;
    line-height:50px;
}
#contactform label.textarea {
    width:100%;
}
#contactform input {
    background-color:transparent;
    width:100%;
}
#contactform textarea {
    width:100%;
    background-color:transparent;
    resize:none;
    height:180px;
}
#contactform .send {
    float:right;
    width:186px;
    height:50px;
    line-height:50px;
    background-color:transparent;
    margin:0px;
}
#contactform .send:hover {
    color:white;
    border:1px solid #ec252a;
    background-color:#ec252a;
}
#contactform .send[disabled] {
    color:#3d3131;
    border:1px solid #3d3131;
    background-color:transparent;
}
/* Small devices (tablets, 768px and down) */
@media (max-width: 768px) {
    #contactform {
        display:none;
    }
    #contact #GMap_map {
        height:400px;
        width:100%;
        border-right:none;
        float:none;
    }
    #contact .text {
        height:auto;
        width:100%;
        padding:50px;
        float:none;
    }
    #contact .text .info .desc {
        width:100%;
        margin: 0;
    }
    #contact .text .leave_msg {
        display:block;
        width:100%;
        font-family:Avenir_Next;
    }
}
/* contact section end */
